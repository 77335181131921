import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

export default function ProjectTile(props) {
	return (
		<div key={props.id} className="border-t border-black pt-4 lg:pt-8">
			<div className="mb-4 md:mb-8">
				<Link to={props.slug}>
					<div className="aspect-landscape relative overflow-hidden bg-black">
						<GatsbyImage
							image={props.image}
							alt={props.title + ' project image'}
							className="absolute inset-0 h-full w-full object-cover z-0"
						/>
					</div>
				</Link>
			</div>
			<div className="flex flex-col justify-between prose">
				<Link to={props.slug} className="mb-4">
					<h2 className="h3">{props.title}</h2>
				</Link>
				<p className="uppercase text-xs">
					{props.categories.map((cat) => cat.title).join(', ')}
				</p>
			</div>
		</div>
	);
}
