import React from 'react';
import { graphql } from 'gatsby';
import ArchiveHeader from '../components/ArchiveHeader';
import ArticleList from '../components/ArticlesList';
import Seo from '../components/Seo';

const ProjectCategoriesPage = (props) => {
	const page = props.data.category || {};
	return (
		<>
			<Seo
				metaTitle={page.seo?.metaTitle || page.title}
				shareTitle={page.seo?.shareTitle || page.title}
				metaDesc={page.seo?.metaDesc}
				shareDesc={page.seo?.shareDesc}
			/>
			<ArchiveHeader
				index={props.data.index}
				categories={props.data.allCategories}
			/>
			<ArticleList articles={props.data.category.article} />
		</>
	);
};

export default ProjectCategoriesPage;

export const query = graphql`
	query ArticleCategoryTemplateQuery($id: String!) {
		index: sanityArticlesIndex {
			id
			title
			slug {
				current
			}
		}
		category: sanityArticleCategories(id: { eq: $id }) {
			title
			id
			seo {
				...seoFields
			}
			article {
				id
				title
				slug {
					current
				}
				categories {
					title
				}
				tileImage {
					asset {
						gatsbyImageData(
							layout: FULL_WIDTH
							fit: FILLMAX
							placeholder: DOMINANT_COLOR
						)
					}
				}
			}
		}
		allCategories: allSanityArticleCategories {
			nodes {
				id
				title
				slug {
					current
				}
			}
		}
	}
`;
