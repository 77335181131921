import React from 'react';
import { Link } from 'gatsby';

export default function ArchiveHeader(props) {
	const categories = props.categories.nodes;
	const index = props.index;

	return (
		<div className="pb-4 md:pb-8 flex flex-wrap items-end">
			<h1 className="sm:mb-0 mr-8">{index.title}</h1>
			<ul className="text-base md:text-xl max-w-sm flex flex-wrap mb-1 lg:mb-2">
				<li className="mr-3">
					<Link
						to={`/` + index.slug.current}
						className="opacity-50 hover:opacity-100 transition duration-200 nav-link"
						activeClassName="opacity-100"
					>
						All
					</Link>
				</li>
				{categories.map((cat) => (
					<li className="mr-3" key={cat.id}>
						<Link
							to={
								`/` +
								index.slug.current +
								`/` +
								cat.slug.current
							}
							className="opacity-50 hover:opacity-100 transition duration-200 nav-link"
							activeClassName="opacity-100"
						>
							{cat.title}
						</Link>
					</li>
				))}
			</ul>
		</div>
	);
}
