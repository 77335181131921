import React from 'react';
import ArticleTile from './ArticleTile';

export default function ArticleList(props) {
	return (
		<div className="grid grid-cols-1 lg:grid-cols-2 gap-4 gap-x-8 lg:gap-x-16 gap-y-4 lg:gap-y-8 pb-8 lg:pb-16">
			{props.articles.map((article) => (
				<ArticleTile
					id={article.id}
					title={article.title}
					slug={'/articles/' + article.slug.current}
					categories={article.categories}
					image={
						article.tileImage
							? article.tileImage.asset.gatsbyImageData
							: undefined
					}
					hoverImage={
						article.tileHoverImage
							? article.tileHoverImage.asset.gatsbyImageData
							: undefined
					}
				/>
			))}
		</div>
	);
}
